<template>
  <div :class="{container:true,notScroll:isScroll}">
       <!-- 顶部 -->
    <MoTitle :showLeft="false" title="典型客户" @moTitltFn="navPanel"></MoTitle>
    <div class="moCustomContainer">
      <!-- 头部 -->
      <div class="moTitle center">
        <h1 style="color:#fff;font-size: 1.5rem;margin-bottom:20px">典型客户</h1>
        <button class="showBtn" @click="$router.push('/moShow')">立即预约演示</button>
      </div>
      <div class="contentBgColor center">
        <h2 class="h2Desc">400<span class="jiaTop" style="font-size:20px;margin-right:5px">+</span>高校的最优选择</h2>
        <span class="spanDesc"></span>
        <div class="center">
          <div class="schoolBtn">
          双一流院校 70<span class="jiaTop" style="font-size:20px;color:#fff">+</span>，市场份额 50%<span class="jiaTop" style="font-size:20px;color:#fff">+</span>
          <img class="schoolWave" src="@/assets/home/wave.png" alt="">
          </div>
          <img src="@/assets/Mobile/syl.png" alt="" style="width:100%">
          <div style="font-size: 12px;color:#999;padding:20px 0 20px;text-align:center">排名不分先后</div>
        </div>
      </div>

      <div class="contentBgColor center">
        <div class="center">
          <div class="schoolBtn">
          本科院校 200<span class="jiaTop" style="font-size:20px;color:#fff">+</span>，占比第一
          <img class="schoolWave" src="@/assets/home/wave.png" alt="">
          </div>
          <img src="@/assets/Mobile/bk.png" alt="" style="width:100%">
          <div style="font-size: 12px;color:#999;padding:20px 0 20px;text-align:center">排名不分先后</div>
        </div>
      </div>

      <div class="contentBgColor center">
        <div class="center">
          <div class="schoolBtn">
          职业院校 100<span class="jiaTop" style="font-size:20px;color:#fff">+</span>，占比领先
          <img class="schoolWave" src="@/assets/home/wave.png" alt="">
          </div>
          <img src="@/assets/Mobile/zyyx.png" alt="" style="width:100%">
          <div style="font-size: 12px;color:#999;padding:20px 0 20px;text-align:center">排名不分先后</div>
        </div>
      </div>

      <div class="contentBgColor center">
        <div class="center">
          <div class="schoolBtn">
          科研院所、医院 50<span class="jiaTop" style="font-size:20px;color:#fff">+</span>
          <img class="schoolWave" src="@/assets/home/wave.png" alt="">
          </div>
          <img src="@/assets/MoHome/kys.png" alt="" style="width:100%">
          <div style="font-size: 12px;color:#999;padding:20px 0 20px;text-align:center">排名不分先后</div>
        </div>
      </div>

      <MoBottom></MoBottom>
      <MoFoot></MoFoot>
    </div>
  </div>
</template>

<script>
import MoTitle from '@/components/Motitle'
export default {
  name: 'moCustomer',
  components: {
    MoTitle
  },
  data () {
    return {
      isScroll: false
    }
  },
  methods: {
    navPanel (v) {
      this.isScroll = v
    },
    getHeight () {
      if (window.innerWidth > 800) {
        this.$router.push('/typicalCase/customer')
      }
    }
  },
  created () {
    // 监听可视窗口宽度
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style scoped>
* {

  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;

}
img {
   object-fit: cover;
}
.container{
  width: 100%;
}
.notScroll{
  height: 0rem;
  overflow: hidden;
}
.moreBtn{
  width: 6rem;
  height: 2rem;
  border-radius: 1.875rem;
  border: 1px solid rgba(211, 218, 228, 0.5);
  line-height: 2rem;
  color: #444444;
  font-size: .875rem;
  background-color: #fff;
}
.h2Desc{
  font-size: 1.375rem;
  color: #222;
  margin-top: 2.5rem;
}
.spanDesc{
  display: inline-block;
  background-color: #D70C0C;
  width: 1.875rem;
  height: .25rem;
  border-radius: .1875rem;
  margin-top: 1.25rem;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.showBtn{
  width: 8.75rem;
  height: 2.5rem;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 2.125rem;
  border: 0;
  line-height: 2.5rem;
  font-size: .875rem;
  color: #fff;
}
.contentBgColor{
  width: 100%;
    padding: 0 1.25rem;
box-sizing: border-box;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}

.moTitle{
  position: relative;
  width: 100%;
  height: 15rem;
  background: url("~@/assets/Cooperation/Partnership/banner.png") center no-repeat;
  padding: 0 2.5rem;
  background-size: cover;
  object-fit: cover;

  color: #fff;
  text-align: center;
    display: flex;
  flex-direction: column;
  justify-content: center;
}
.moCustomContainer{
  padding-top: 2.75rem;
}

.schoolBtn{
  position: relative;
  top: 0;
  left: 0;
  width: 360px;
  height: 60px;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  box-shadow: 0px 8px 32px -6px rgba(131,7,7,0.4);
  border-radius: 10px;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  padding: 0 20px;
  margin: 20px 0;
  overflow: hidden;
}
.schoolWave{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 500px;
}

.jiaTop{
  position: relative;
  top: -10px;
  left: 0;
}
</style>
